<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <TopBar
            :show-currencies="false"
            :show-delete="false"
            :show-languages="false"
            :show-select="false"
            :show-add="false"
            :methods="methods"
          />
          <div class="row overflow-hidden mt-4">
            <div class="timeline-container w-100 dragscroll fix-height">
              <div class="timeline">
                <div class="timeline-head">
                  <div class="timeline-title"></div>
                  <div
                    class="timeline-month"
                    :style="'width:' + dayWith + 'px'"
                    :class="[yearChange(i) ? 'year' : '']"
                    v-for="(date, i) in dates"
                    :key="date.i"
                  >
                    {{ date.year }} {{ date.monthName }} {{ date.day }}
                  </div>
                </div>
                <div
                  class="timeline-item"
                  v-for="service in list"
                  :key="service.id"
                >
                  <div class="timeline-title d-grid">
                    <div class="timeline-name">{{ service.name }}</div>
                    <div class="row ms-3">
                      <div class="col-6">{{ service.type }}</div>
                      <div class="col-4">
                        {{ $t("manufacturingGrant.kovcsAnna") }}
                      </div>
                      <div class="col-2">20%</div>
                    </div>
                  </div>
                  <div
                    class="timeline-line"
                    v-for="(line, i) in service.times"
                    :key="line.id"
                    :style="
                      'margin-left:' +
                      start(service, i, line.start_time) +
                      'px;width: ' +
                      width(line.start_time, line.end_time) +
                      'px'
                    "
                    :title="line.name + ' ' + line?.product?.serial"
                  >
                    <div class="timeline-start-date">
                      {{ line.name }}
                      {{ line?.product?.serial }}
                    </div>
                    <div
                      class="timeline-end-date"
                      v-if="+width(line.start_time, line.end_time) > 200"
                    >
                      {{ date(line.end_time) }}
                    </div>
                    <div
                      class="timeline-color"
                      :style="'background: #' + line.color"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import TopBar from "../../components/lists/TopBar";

export default {
  name: "ManufacturingGrant",
  components: { TopBar },
  created() {},
  data() {
    return {
      dayWith: 620,
      viewType: 0,
      methods: [
        {
          title: "Product view",
          icon: "box",
          always: true,
          method: () => {
            this.viewType = 0;
          },
        },
        {
          title: "User view",
          icon: "partners",
          always: true,
          method: () => {
            this.viewType = 1;
          },
        },
        {
          title: "Machine view",
          icon: "scissor",
          always: true,
          method: () => {
            this.viewType = 2;
          },
        },
        {
          title: "Zoom in",
          icon: "plus-circle",
          always: true,
          method: () => {
            if (this.dayWith > 2000) {
              return;
            }
            this.dayWith += 200;
          },
        },
        {
          title: "Zoom out",
          icon: "minus-circle",
          always: true,
          method: () => {
            if (this.dayWith < 300) {
              return;
            }
            this.dayWith -= 200;
          },
        },
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      services: [
        {
          id: 11,
          name: "A-4700 Snake bikini bottom",
          type: "Szabás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1706868017,
              end_time: 1707415217,
              name: "[20%] Nagy Éva",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "",
          type: "Varrás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1707415217,
              end_time: 1707926417,
              name: "[0%] Kovács Anna",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "",
          type: "Csomagolás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1707926417,
              end_time: 1708279217,
              name: "[0%] Nagy Sára",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "A-5600 bikini bottom",
          type: "Szabás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1706460817,
              end_time: 1707120017,
              name: "[20%] Kiss Imre",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "",
          type: "Varrás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1707120017,
              end_time: 1707408017,
              name: "Szabó Sára [0%]",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "",
          type: "Csomagolás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1707408017,
              end_time: 1707494417,
              name: "[0%]",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "A-10200 bikini top",
          type: "Szabás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1707235217,
              end_time: 1707926417,
              name: "[20%] Kovács Anna",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "",
          type: "Varrás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1707926417,
              end_time: 1708099217,
              name: "[0%]",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "",
          type: "Csomagolás",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1708099217,
              end_time: 1708272017,
              name: "[0%]",
              color: "00796b",
            },
          ],
        },
      ],
      users: [
        {
          id: 11,
          name: "Kiss Imre",
          type: "type",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1706877137,
              end_time: 1708605137,
              name: "A-4700 Snake bikini bottom szabás",
              color: "00796b",
            },
            {
              id: 13,
              service: 11,
              start_time: 1708605137,
              end_time: 1708777937,
              name: "A-4700 Snake bikini bottom varrás",
              color: "00796b",
            },
            {
              id: 13,
              service: 11,
              start_time: 1708777937,
              end_time: 1708970417,
              name: "A-4700 Snake bikini bottom csomagolás",
              color: "00796b",
            },
          ],
        },
      ],
      machines: [
        {
          id: 11,
          name: "Varrógép 1",
          type: "type",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1624280663,
              end_time: 1668799897,
              name: "A-4700 Snake bikini bottom",
              color: "00796b",
            },
            {
              id: 13,
              service: 11,
              start_time: 1668843815,
              end_time: 1668972697,
              name: "A-1020",
              color: "00796b",
            },
            {
              id: 13,
              service: 11,
              start_time: 1668972697,
              end_time: 1669133926,
              name: "A-3450",
              color: "00796b",
            },
          ],
        },
        {
          id: 11,
          name: "Varrógép 2",
          type: "type",
          type_color: "90daf0",
          times: [
            {
              id: 13,
              service: 11,
              start_time: 1624280663,
              end_time: 1668799897,
              name: "A-1020",
              color: "00796b",
            },
            {
              id: 13,
              service: 11,
              start_time: 1668843815,
              end_time: 1668972697,
              name: "A-3450",
              color: "00796b",
            },
            {
              id: 13,
              service: 11,
              start_time: 1668972697,
              end_time: 1669133926,
              name: "A-5560",
              color: "00796b",
            },
          ],
        },
      ],
    };
  },
  computed: {
    list() {
      if (this.viewType === 1) {
        return this.users;
      } else if (this.viewType === 2) {
        return this.machines;
      }

      return this.services;
    },
    now() {
      const now = new Date();
      const startOfDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      return startOfDay / 1000;
    },
    dates() {
      let dates = [];
      const d = new Date();
      let day = d.getDate();
      let month = d.getMonth();
      let year = d.getFullYear();
      for (let i = 0; i < 30; i++) {
        dates.push({
          day: day,
          year: year,
          month: month,
          monthName: this.months[month],
          i: i,
        });
        day++;
        if (day > this.getLastDayOfMonth(year, month)) {
          day = 1;
          month++;
          if (month > 11) {
            month = 0;
            year++;
          }
        }
      }

      return dates;
    },
  },
  mounted() {
    this.loadData();
    const slider = document.querySelector(".dragscroll");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;
      slider.scrollLeft = scrollLeft - walk;
    });
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    loadData() {
      /* http.fetch("/production/planner").then((data) => {
        this.users = data.crm_items;
      });*/
    },
    getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 0).getDate();
    },
    date(val) {
      dayjs.locale("en");
      dayjs.extend(localizedFormat);
      dayjs.extend(relativeTime);
      return dayjs().to(dayjs.unix(val));
    },
    diff(d1, d2) {
      let time;
      time = (d2.getTime() - d1.getTime()) / 1000;
      return time <= 0 ? 0 : time;
    },
    width(start, end) {
      let d1 = new Date(this.now > start ? this.now * 1000 : start * 1000);
      let d2 = new Date(end * 1000);
      return this.diff(d1, d2) * (this.dayWith / 86400);
    },
    start(service, i, start) {
      let d1 = new Date(
        i === 0 ? this.now * 1000 : service.times[i - 1].end_time * 1000
      );
      let d2 = new Date(start * 1000);
      if (d1 > d2) {
        return 0;
      }
      return this.diff(d1, d2) * (this.dayWith / 86400);
    },
    yearChange(i) {
      if (i === 0) {
        return false;
      }
      return this.dates[i - 1].year !== this.dates[i].year;
    },
  },
};
</script>
<style>
.timeline-container {
  height: calc(100vh - 230px);
  overflow-x: scroll;
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.timeline-container .timeline {
  width: max-content;
  position: relative;
  margin-left: calc(var(--bs-gutter-x) / 2);
}
.timeline-container .timeline .timeline-line {
  height: 52px;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 15px 10px;
  background: #f7f8ff;
  margin: 2px 0 0;
  box-shadow: 0 0 2px 1px rgba(202, 202, 202, 60);
}
.timeline-container .timeline .timeline-line .timeline-start-date {
  position: sticky;
  left: 432px;
  margin-right: 136px;
  color: var(--gray-dark);
  background: #f7f8ff;
  z-index: 1;
  font-weight: 500;
  float: left;
}
.timeline-container .timeline .timeline-line .timeline-end-date {
  position: sticky;
  right: 10px;
  color: var(--gray-dark);
  font-weight: 500;
  float: right;
}
.timeline-container .timeline .timeline-line .timeline-color {
  position: sticky;
  left: 424px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: -10px;
}
.timeline-container .timeline .timeline-head {
  display: flex;
  padding-bottom: 1em;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
}
.timeline-container .timeline .timeline-head .timeline-month {
  width: 240px;
  border-left: 1px solid var(--gray);
  padding-left: 10px;
}
.timeline-container .timeline .timeline-head .timeline-month.year:before {
  content: "";
  width: 1px;
  height: 100%;
  border-left: 4px solid #713ae8;
  position: absolute;
  margin-left: -13px;
  border-radius: 6px;
}
.timeline-container .timeline .timeline-item {
  display: flex;
  gap: 0;
  margin-bottom: 0.5em;
  padding-bottom: 4px;
}
.timeline-container .timeline .timeline-title {
  width: 420px;
  z-index: 10;
  position: sticky;
  left: 0;
  background: rgb(255 255 255 / 86%);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-bottom: -4px;
}
.timeline-container .timeline .timeline-name {
  font-size: 1.2em;
  font-weight: 500;
  margin-left: 4px;
}
.dragscroll {
  cursor: all-scroll;
}
</style>
